import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Observable, Subject, firstValueFrom, forkJoin, merge, of} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Event, NavigationEnd, ParamMap, Router} from '@angular/router';

import {Feature, Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {Layer_LayerType as LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';
import {GetNearbyFeaturesResponse_NearbyFeature as NearbyFeature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layerservice_pb';
import {
  RelatedFeature,
  RelatedFeaturesGroup_RelatedFeatureRole as RelatedFeatureRole,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';
import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';

import {ASSETS_LAYER_ID, DEFECTS_LAYER_ID} from '../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {CanDeactivateComponent} from '../core/navigation/can_deactivate_guard';
import {AnnotationsService} from '../services/annotations_service';
import {ConfigService} from '../services/config_service';
import {DialogService} from '../services/dialog_service';
import {FeaturesService} from '../services/features_service';
import {LayersService} from '../services/layers_service';
import {NetworkService} from '../services/network_service';
import {PendingUploadService} from '../services/pending_upload_service';
import {PhotosService} from '../services/photos_service';
import {UploadResponse, UploadService} from '../services/upload_service';
import {Annotation, PendingAnnotatedImage} from '../typings/annotations';
import {
  GlobalFormPropertyName,
  PendingFile,
  PendingUploadGroup,
  UploadDialogData,
  UploadForm,
  UploadFormTemplate,
  UploadState,
} from '../typings/upload';
import {getPropertyValue, getRelatedFeatures, getRelatedIds} from '../utils/feature';
import {loadPendingFile} from '../utils/image';
import {waitFor} from '../utils/rxjs';
import {ResetFormDialog} from './dialogs/reset_form_dialog';
import {InitialMapMetadata} from './feature_selection_map/feature_selection_map';
import {
  IMAGE_UPLOAD_FORM,
  NEW_FORMS,
  UPLOAD_FORMS_BY_TYPE,
  UploadFormResponses,
} from './upload_form/forms';

const ERROR_TOAST_DURATION = 1000;
const UPLOAD_COMPLETE_HEADER = 'Upload complete';
const EDIT_SUCCESS_HEADER = 'Edit complete';
const UPLOAD_FAILED_HEADER = 'Upload failed';
const EDIT_FAILED_HEADER = 'Edit failed';

/**
 * The values that are fetched when the form is being edited.
 */
interface ExistingMetadata {
  imageGroup: Feature | null;
  images: Image[] | null;
  feature: Feature | null;
}

/**
 * Simple upload page for use from mobile clients.
 */
@Component({
  selector: 'upload-page',
  templateUrl: './upload.ng.html',
  styleUrls: ['./upload.scss'],
})
export class UploadPage implements OnInit, OnDestroy, CanDeactivateComponent, AfterContentInit {
  // Set of parameters for opening upload form, this will be inputted via this @input
  // if the form is opening in a dialog and will be scraped from the query
  // parameters if the form is opening as its own page.
  @Input() dialogData: UploadDialogData | null = null;

  @Output() readonly headerChanged = new EventEmitter<string>();
  // Emits when the dialog should be closed if the form is opened in a dialog.
  @Output() readonly closeDialog = new EventEmitter<void>();

  destroyed = new Subject<void>();

  // A user's network-connection state.
  offline = true;
  // Whether the form is in 'Edit' mode (vs new upload).
  // There is also the case when user edits pending (not yet uploaded) defect.
  // In this case the 'pendingUploadId' parameter will be populated, indicating
  // the pending upload to be edited.
  editing: boolean | null = null;
  loading = false;
  complete = false;

  uploadForm: UploadForm = {
    tags: new Set<string>(),
    externalId: '',
  };
  // The initial location for the map's center and asset to select.
  initialMapMetadata: InitialMapMetadata | null = null;
  // The location of the map pin if one exists.
  selectedMapLocation: LatLng | null = null;
  // The added or existing image group depending whether in edit mode.
  imageGroup: Feature | null = null;
  // Images that already exist as part of the image group. There will only be
  // existing images when in edit mode.
  existingImages: Image[] = [];
  // A selected or related feature to which the image group will be associated.
  feature: Feature | null = null;
  // Existing images that need to be deleted.
  imagesToDelete: Image[] = [];
  uploadResponse: UploadResponse | null = null;
  layerId = DEFECTS_LAYER_ID;
  // The layer ID of the layer that will be loaded in the selection map.
  mapSelectionLayerId = '';

  formTemplate: UploadFormTemplate = IMAGE_UPLOAD_FORM;
  availableFormTemplates: UploadFormTemplate[] = [];
  formResponses: UploadFormResponses | null = null;
  // Part of the showNewForm() hackiness (see below for additional info).
  currentForm: UploadFormTemplate | null = null;

  private pendingUploadGroup: PendingUploadGroup | null = null;
  pendingFiles: PendingFile[] = [];
  uploadState = UploadState.PENDING;
  // Whether the pending state (unsubmitted files and annotations) should be
  // cleared up or restored on load. Defaults to clear state unless explicitly
  // instructed otherwise.
  preserveState = false;
  // ID of the current upload, saved in IndexedDB for potential retry in case of
  // failure.
  uploadId: number = -1;
  // ID of the pending upload that has already been saved to the DB. In editing
  // scenario, this entry will be re-written with the new upload id on save.
  pendingUploadId: number = -1;
  readonly UploadState = UploadState;

  // Flag for whether the upload form is being opened in a dialog.
  openingInDialog = false;

  constructor(
    private readonly annotationsService: AnnotationsService,
    private readonly configService: ConfigService,
    private readonly dialogService: DialogService,
    private readonly featuresService: FeaturesService,
    private readonly layersService: LayersService,
    private readonly networkService: NetworkService,
    private readonly pendingUploadService: PendingUploadService,
    private readonly photoService: PhotosService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly uploadService: UploadService,
  ) {}

  ngOnInit() {
    this.init();
  }

  ngAfterContentInit() {
    this.headerChanged.emit(this.getHeader());
  }

  ngOnDestroy() {
    if (this.openingInDialog && !this.uploadService.isUploadDialogInterrupted()) {
      this.uploadService.resetUploadDialogInfo();
    }
    this.destroyed.next();
    this.destroyed.complete();
  }

  init() {
    this.setOpeningInDialog();
    if (this.openingInDialog) {
      this.uploadService.resetUploadDialogInfo();
      this.uploadService.setUploadFormOpenedInDialog(true);
      const reopenUploadDialogData: UploadDialogData = {
        ...this.dialogData!,
        preserveState: true,
      };
      this.uploadService.setUploadDialogData(reopenUploadDialogData);
    }
    if (!this.openingInDialog) {
      // Explicitly handle navigation event to reload upload form
      // if same route is refreshed.
      this.router.events
        .pipe(
          filter((event: Event) => event instanceof NavigationEnd),
          map((): boolean => this.getStatePersistenceFromRouteSnapshot()),
          takeUntil(this.destroyed),
        )
        .subscribe((preserveState: boolean) => {
          if (!preserveState && !this.pendingUploadService.hasPendingState()) {
            this.resetFormState();
          }
        });
    }
    const availableUploadFormTypes = this.configService.availableUploadFormTypes;
    for (const uploadFormType of availableUploadFormTypes) {
      if (
        !UPLOAD_FORMS_BY_TYPE.has(uploadFormType) ||
        (NEW_FORMS.has(uploadFormType) && !this.configService.newFormsEnabled)
      ) {
        continue;
      }
      this.availableFormTemplates.push(UPLOAD_FORMS_BY_TYPE.get(uploadFormType)!);
    }
    this.networkService
      .getOffline$()
      .pipe(takeUntil(this.destroyed))
      .subscribe((isOffline: boolean) => {
        this.offline = isOffline;
      });
    this.handleQueryParams();
  }

  setOpeningInDialog() {
    if (this.dialogData) {
      this.openingInDialog = true;
      this.editing = !!this.dialogData.edit;
      this.preserveState = !!this.dialogData.preserveState;
      this.headerChanged.emit(this.getHeader());
    }
  }

  /**
   * Depending on unsaved changes and whether the navigation is within form flow
   * (to/from annotations editor) or not, decide whether we should ask user for
   * confirmation before deactivating the component.
   */
  canDeactivate(nextUrl: string): Observable<boolean> | boolean {
    if (this.isAnnotationUrl(nextUrl) || !this.pendingUploadService.hasPendingState()) {
      return true;
    }
    return this.openResetFormDialog();
  }

  private handleQueryParams() {
    // Set the editing mode separately to avoid
    // 'ExpressionChangedAfterItHasBeenCheckedError'. This error is triggerd if
    // you try to update the value of a template variable as part of the
    // ngAfterViewInit call stack.
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged(),
        tap((queryParamMap: ParamMap) => {
          if (!this.openingInDialog) {
            this.editing = !!queryParamMap.get(QUERY_PARAMS.EDIT);
            this.preserveState = !!queryParamMap.get(QUERY_PARAMS.PRESERVE_STATE);
          }
        }),
        waitFor(this.layersService.onLayersReady()),
        takeUntil(this.destroyed),
      )
      .subscribe(() => {
        if (!this.preserveState && !this.uploadService.isUploadDialogInterrupted()) {
          this.pendingUploadService.clearPendingState();
        }
        this.mapSelectionLayerId = this.layersService.getLayerIdFromType(LayerType.ASSETS);
        this.formTemplate =
          this.pendingUploadService.getPendingFormTemplate() || this.availableFormTemplates[0];
        // Part of the showNewForm() hackiness (see below for additional
        // info).
        this.currentForm = this.formTemplate;
      });

    if (this.openingInDialog) {
      // Handles the case where upload form is opened in a dialog for editing.
      this.processDialogFeatureAndLayerIdQuery();
      // Handles the case where upload form is opened in a dialog for uploading new photos of an
      // asset.
      this.processDialogAssetQuery();
    } else {
      // Monitors for <featureId, layerId> type of query.
      this.processFeatureAndLayerIdQuery();
      // Monitors for <assetId, extAssetId> type of query.
      this.processAssetQuery();
      // Monitors for <pendingUploadId> type of query.
      this.processEditPendingUploadQuery();
    }
  }

  shouldShowUploadForm(): boolean {
    const editingReady = this.editing && this.formResponses !== null;
    const shouldShow =
      this.currentForm === this.formTemplate && (editingReady || this.editing === false);
    return shouldShow;
  }

  // Scenario: Edit existing defect (edit flow).
  private processFeatureAndLayerIdQuery() {
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged(),
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.EDIT)),
        mergeMap((queryParamMap: ParamMap) => {
          const featureId = queryParamMap.get(QUERY_PARAMS.FEATURE_ID) || '';
          if (queryParamMap.has(QUERY_PARAMS.LAYER_ID)) {
            this.layerId = queryParamMap.get(QUERY_PARAMS.LAYER_ID)!;
          }
          return this.getExistingMetadata(featureId);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((existingMetadata: ExistingMetadata) => {
        this.preloadFormWithMetadata(existingMetadata);
      });
  }

  // Scenario: Edit existing defect in a dialog.
  private processDialogFeatureAndLayerIdQuery() {
    if (!this.dialogData!.edit) {
      return;
    }
    if (this.dialogData!.layerId) {
      this.layerId = this.dialogData!.layerId;
    }
    const featureId = this.dialogData!.featureId || '';
    this.getExistingMetadata(featureId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((existingMetadata: ExistingMetadata) => {
        this.preloadFormWithMetadata(existingMetadata);
      });
  }

  // Scenario: Add photos of asset (new upload flow).
  private processAssetQuery() {
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged(),
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.ASSET_ID)),
        waitFor(this.layersService.onLayersReady()),
        mergeMap((queryParamMap: ParamMap) => {
          const assetId = queryParamMap.get(QUERY_PARAMS.ASSET_ID) || '';
          if (this.offline) {
            const assetExternalId = queryParamMap.get(QUERY_PARAMS.EXT_ASSET_ID) || '';
            return of(new Feature({id: assetId, externalId: assetExternalId}));
          }
          return this.requestAssetById(assetId);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((asset: Feature | null) => {
        if (!asset) {
          this.snackBar.open('Could not prepopulate associated asset.', '', {
            duration: ERROR_TOAST_DURATION,
          });
          return;
        }
        this.feature = asset;
        this.formResponses = assetInitialFormData(asset, !this.offline);
        const location = getPointLocation(asset);
        if (!location) {
          console.error('Failed to get location for asset: ', asset);
          return;
        }
        this.initialMapMetadata = {
          featureId: asset.id,
          center: location,
        };
      });
  }

  // Scenario: Add photos for an asset in dialog (new upload flow).
  private processDialogAssetQuery() {
    if (!this.dialogData!.assetId) {
      return;
    }

    this.layersService
      .onLayersReady()
      .pipe(
        mergeMap(() => {
          const assetId = this.dialogData!.assetId || '';
          if (this.offline) {
            const assetExternalId = this.dialogData!.externalId || '';
            return of(new Feature({id: assetId, externalId: assetExternalId}));
          }
          return this.requestAssetById(assetId);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((asset: Feature | null) => {
        if (!asset) {
          this.snackBar.open('Could not prepopulate associated asset.', '', {
            duration: ERROR_TOAST_DURATION,
          });
          return;
        }
        this.feature = asset;
        this.formResponses = assetInitialFormData(asset, !this.offline);
        const location = getPointLocation(asset);
        if (!location) {
          console.error('Failed to get location for asset: ', asset);
          return;
        }
        this.initialMapMetadata = {
          featureId: asset.id,
          center: location,
        };
      });
  }

  // Scenario: edit pre-saved upload (defect added either while offline or
  // upload that has failed for any reason and was saved to pending uploads
  // queue).
  private processEditPendingUploadQuery() {
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged(),
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.PENDING_UPLOAD_ID)),
        map((queryParamMap: ParamMap): number =>
          Number(queryParamMap.get(QUERY_PARAMS.PENDING_UPLOAD_ID)!),
        ),
        filter((uploadId: number) => !isNaN(uploadId)),
        tap((uploadId: number) => {
          this.pendingUploadId = uploadId;
          this.loading = true;
        }),
        mergeMap((uploadId: number) => this.requestPendingUploadData(uploadId)),
        takeUntil(this.destroyed),
      )
      .subscribe({
        next: ([pendingUploadGroup, uploadFormResponses]) => {
          this.preloadForm(pendingUploadGroup, uploadFormResponses);
          this.loading = false;
        },
        error: (error: Error) => {
          this.loading = false;
          console.error(`Failed to load pending upload entry. ${error.message}`);
          this.snackBar.open('Could not load pending upload entry.', '', {
            duration: ERROR_TOAST_DURATION,
          });
        },
      });
  }

  private requestPendingUploadData(
    pendingUploadId: number,
  ): Observable<[PendingUploadGroup, UploadFormResponses]> {
    return this.uploadService.getPendingUpload(pendingUploadId).pipe(
      takeUntil(this.destroyed),
      mergeMap(
        (
          uploadGroup: PendingUploadGroup | null,
        ): Observable<[PendingUploadGroup, UploadFormResponses]> => {
          if (!uploadGroup) {
            throw new Error(`Failed to load pending upload with id ${pendingUploadId}`);
          }
          const assetId = uploadGroup.uploadForm?.externalId || '';
          const asset =
            assetId !== '' ? this.uploadService.searchAssociatedAsset(assetId) : of(null);
          return asset.pipe(
            map((feature: Feature | null): [PendingUploadGroup, UploadFormResponses] => [
              uploadGroup,
              this.getUploadFormResponses(uploadGroup, feature),
            ]),
          );
        },
      ),
    );
  }

  private getUploadFormResponses(
    uploadGroup: PendingUploadGroup,
    relatedAsset: Feature | null,
  ): UploadFormResponses {
    return {
      pendingFiles: [],
      existingImages: [],
      imagesToDelete: [],
      tags: uploadGroup.uploadForm.tags,
      location: getPointLocation(relatedAsset) || uploadGroup.locationFromBrowser,
      asset: relatedAsset,
      externalId: relatedAsset?.externalId || uploadGroup.uploadForm.externalId || '',
      extraProperties: uploadGroup.uploadForm.extraProperties || [],
    };
  }

  private requestAssetById(assetId: string): Observable<Feature | null> {
    const assetLayerId = this.layersService.getLayerIdFromType(LayerType.ASSETS) || '';
    return this.featuresService.getFeature(assetLayerId, assetId, false).pipe(
      catchError(() => {
        console.error('Failed to fetch asset with ID: ', assetId);
        return of(null);
      }),
    );
  }

  private getExistingFormData(
    imageGroup: Feature,
    asset: Feature | null,
    images: Image[],
  ): UploadFormResponses {
    return {
      pendingFiles: [],
      existingImages: images,
      tags: new Set<string>((imageGroup?.tags || []).map((tag: Tag) => tag.name)),
      location: getPointLocation(imageGroup)!,
      asset,
      externalId: asset?.externalId || '',
      extraProperties: imageGroup.properties,
    };
  }

  private getInitialMapMetadata(
    imageGroup: Feature,
    relatedFeature: Feature | null,
  ): InitialMapMetadata {
    const location = relatedFeature
      ? getPointLocation(relatedFeature)!
      : getPointLocation(imageGroup)!;
    return {
      featureId: this.feature?.id || '',
      center: location,
    };
  }

  private getExistingMetadata(featureId: string): Observable<ExistingMetadata> {
    return this.featuresService.getFeature(this.layerId, featureId, false).pipe(
      first(),
      mergeMap((feature: Feature | null) => {
        if (!feature) {
          throw new Error(
            `Feature with ID "${featureId}" on layer with ` + `ID "${this.layerId}" not found.`,
          );
        }
        const relatedImageIds = getRelatedIds(feature, RelatedFeatureRole.CHILD_IMAGE);
        const relatedAssets = getRelatedFeatures(feature, RelatedFeatureRole.PARENT_ASSET);
        // TODO(b/307798159, b/307765666): Relying on layer id to ensure
        // that we are selecting the GIS asset rather than the
        // autotopology asset is brittle, we should either add an
        // AUTOTOPOLOGY_PARENT_ASSET field as in b/307798159 or get rid
        // of RelatedFeatureRole entirely as in b/307765666.
        const relatedGisAsset = relatedAssets.find(
          (relatedAsset: RelatedFeature) => relatedAsset.layerId === ASSETS_LAYER_ID,
        );

        return forkJoin({
          imageGroup: of(feature),
          images: this.photoService.getImagesByIds(relatedImageIds).pipe(first()),
          // TODO(b/174520147): Generalize uploads to work for any type of
          // feature.
          feature: relatedGisAsset
            ? this.featuresService
                .getFeature(ASSETS_LAYER_ID, relatedGisAsset.id, false)
                .pipe(first())
            : of(null),
        });
      }),
      catchError(() => {
        // TODO(reubenn): Add some type of debug-time logging.
        return of({imageGroup: null, images: null, feature: null});
      }),
    );
  }

  getHeader(): string {
    if (this.offline && this.complete) {
      return 'Image(s) added to pending uploads';
    }
    // If the editing status (from the URL) is not determined yet, then just
    // return an empty string.
    if (!this.openingInDialog && this.editing === null) {
      return '';
    }
    switch (this.uploadState) {
      case UploadState.PENDING:
        return this.editing ? 'Edit image group' : 'Upload asset image';
      case UploadState.EDIT_SUCCEEDED:
        return EDIT_SUCCESS_HEADER;
      case UploadState.SUCCEEDED:
        return UPLOAD_COMPLETE_HEADER;
      default:
        return this.editing ? EDIT_FAILED_HEADER : UPLOAD_FAILED_HEADER;
    }
  }

  private async saveAsPendingUpload(): Promise<number> {
    let uploadId = -1;
    if (this.pendingUploadGroup) {
      try {
        uploadId = await firstValueFrom(
          this.uploadService.saveImageGroupToPendingQueue(this.pendingUploadGroup),
        );

        if (this.pendingUploadId > 0) {
          await firstValueFrom(this.uploadService.deletePendingUpload(this.pendingUploadId));
        }
      } catch (error: unknown) {
        this.snackBar.open('Could not save images. Please try again later', 'Dismiss');
      }
      this.complete = true;
    }
    return uploadId;
  }

  async triggerUpload(formResponses: UploadFormResponses) {
    this.formResponses = formResponses;
    this.feature = formResponses.asset;
    this.pendingFiles = formResponses.pendingFiles;
    this.existingImages = formResponses.existingImages || [];
    this.imagesToDelete = formResponses.imagesToDelete || [];
    this.selectedMapLocation = formResponses.location;
    this.uploadForm = convertResponsesToForm(formResponses);
    this.closeDialog.next();
    await this.prepareAndSubmitForUpload();
  }

  async prepareAndSubmitForUpload() {
    // Using only scroll didn't work.
    // @see https://stackoverflow.com/questions/1925671/javascript-window-scroll-vs-window-scrollto
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.loading = true;

    if (!this.feature) {
      this.feature = await firstValueFrom(this.getAssetForAssociation());
    }

    const location =
      this.uploadService.getLocationFromBrowser() ||
      getPointLocation(this.feature) ||
      this.formResponses!.location;

    this.uploadService.setLocationFromBrowser(location);

    this.pendingUploadGroup = {
      uploadForm: this.uploadForm,
      files: this.pendingFiles.map((pendingFile: PendingFile) => pendingFile.file),
      uploadedAt: new Date(),
      locationFromBrowser: location,
      annotationsPerFile: await this.getAllPendingAnnotations(),
    };

    if (this.offline) {
      // Save upload in case it fails and needs to be retried later.
      // In case we are in offline mode, this is all we can do.
      this.uploadId = await this.saveAsPendingUpload();
      this.loading = false;
      this.complete = true;
      return;
    }

    // Mapping of file names to internal reference IDs - primary use case is
    // associating annotations for unsaved images.
    // TODO(halinab): find more intuitive way of associating pending ids - by
    // possibly encapsulating all information about upload in one object.
    const referenceIdsByFileNames = new Map(
      this.pendingFiles.map((pendingFile) => [pendingFile.file.name, pendingFile.id]),
    );
    this.uploadService.triggerUploadImagesOfAsset(
      this.pendingUploadGroup,
      location,
      this.feature,
      this.formTemplate.name,
      this.existingImages,
      this.imageGroup,
      referenceIdsByFileNames,
      this.pendingUploadId,
    );
    this.router.navigateByUrl(ROUTE.MAP);
  }

  /**
   * Retrieves pending annotation state for provided file.
   */
  private getAnnotationsForFile(file: File): Observable<Annotation[]> {
    const fileReferenceId = this.getReferenceIdForFile(file);
    return this.annotationsService.getPendingAnnotations(fileReferenceId).pipe(
      map((annotationsState: PendingAnnotatedImage): Annotation[] => annotationsState.annotations),
      takeUntil(this.destroyed),
    );
  }

  /**
   * Retrieves pending annotation state for all tracked files. This can further
   * be saved in IndexedDb for retries/delayed upload.
   */
  private async getAllPendingAnnotations(): Promise<Annotation[][]> {
    const annotationsPerFile: Annotation[][] = [];
    for (const pendingFile of this.pendingFiles) {
      const annotations = await firstValueFrom(this.getAnnotationsForFile(pendingFile.file));
      annotationsPerFile.push(annotations);
    }
    return annotationsPerFile;
  }

  // Retrieves the ID image was referenced by before it got saved (saving
  // overrides the ID).
  private getReferenceIdForFile(file: File): string {
    // TODO(halinab): find more robust way of associating reference IDs.
    return (
      this.pendingFiles.find((searchFile: PendingFile) => searchFile.file.name === file.name)?.id ||
      ''
    );
  }

  private getAssetForAssociation(): Observable<Feature | null> {
    if (this.editing) {
      return of(null);
    }
    return this.featuresService
      .getNearbyFeatures({
        layerId: ASSETS_LAYER_ID,
        location: this.formResponses!.location!,
        maxResults: 1,
      })
      .pipe(
        map((nearbyFeatures: NearbyFeature[]) => {
          if (nearbyFeatures.length === 0) {
            throw new Error('No nearby assets found');
          }
          const feature = nearbyFeatures[0].feature;
          if (!feature) {
            throw new Error('Missing feature in GetNearbyFeatures response');
          }
          return feature;
        }),
        catchError((error: Error) => {
          console.error(error.message);
          return of(null);
        }),
      );
  }

  private getStatePersistenceFromRouteSnapshot(): boolean {
    return (
      this.router.routerState.snapshot.root.firstChild?.queryParams[
        QUERY_PARAMS.PRESERVE_STATE
      ]?.toLowerCase() === 'true'
    );
  }

  /**
   * Pre-loads form values with existing metadata (defect that has already been
   * saved to the database).
   */
  private preloadFormWithMetadata(existingMetadata: ExistingMetadata | null) {
    if (!existingMetadata) {
      return;
    }
    const {images, imageGroup, feature} = existingMetadata;
    if (!imageGroup || !images) {
      console.error('Error: Missing image group or images');
      const snackBarRef = this.snackBar.open('Could not load defect for editing', '', {
        duration: ERROR_TOAST_DURATION,
      });
      snackBarRef.afterDismissed().subscribe(() => {
        this.router.navigateByUrl(ROUTE.MAP);
      });
      return;
    }
    this.imageGroup = imageGroup;
    this.existingImages = images;
    this.feature = feature;
    this.formResponses = this.getExistingFormData(imageGroup, feature, images);
    this.initialMapMetadata = this.getInitialMapMetadata(imageGroup, feature);
    const editingFormTemplate = this.getEditingFormTemplate(imageGroup.properties);
    if (editingFormTemplate) {
      this.formTemplate = editingFormTemplate;
      this.showNewForm();
    }
  }

  /**
   * Pre-loads form values with previously populated info (form values that have
   * been saved to the pending uploads queue).
   */
  private preloadForm(
    pendingUploadGroup: PendingUploadGroup,
    uploadResponses: UploadFormResponses,
  ) {
    this.pendingUploadGroup = pendingUploadGroup;
    this.uploadForm = pendingUploadGroup.uploadForm;
    this.formResponses = uploadResponses;
    if (!this.preserveState) {
      this.loadPendingFilesAndAnnotations(
        pendingUploadGroup.files,
        pendingUploadGroup.annotationsPerFile,
      );
    }
    const asset = uploadResponses.asset;
    const location = uploadResponses.location;
    if (location) {
      this.initialMapMetadata = {
        featureId: asset?.id || '',
        center: location,
      };
    }
    const editingFormTemplate = this.getEditingFormTemplate(uploadResponses.extraProperties);
    if (editingFormTemplate) {
      this.formTemplate = editingFormTemplate;
      this.showNewForm();
    }
  }

  private getEditingFormTemplate(properties?: Property[]) {
    const formTemplateName = getPropertyValue(GlobalFormPropertyName.FORM_TYPE, properties || []);
    return this.availableFormTemplates.filter((formTemplate: UploadFormTemplate) => {
      return formTemplate.name === formTemplateName;
    })[0];
  }

  private loadPendingFilesAndAnnotations(files: File[], annotations: Annotation[][]) {
    this.pendingFiles = [];
    if (this.formResponses) {
      this.formResponses.pendingFiles = [];
    }
    const loadOperations = files.map(
      (file: File): Observable<PendingFile> => loadPendingFile(file),
    );
    merge(...loadOperations)
      .pipe(takeUntil(this.destroyed))
      .subscribe((pendingFile: PendingFile) => {
        this.pendingFiles.push(pendingFile);
        this.formResponses?.pendingFiles.push(pendingFile);
        this.pendingUploadService.addPendingImage(pendingFile.id, pendingFile);
        const fileIndex = files.findIndex((file: File) => pendingFile.file.name === file.name);
        if (fileIndex < 0 || annotations.length < fileIndex) {
          return;
        }
        this.saveAnnotationsOfPendingUpload(pendingFile, annotations[fileIndex]);
      });
  }

  /**
   * Resets the form and upload state.
   */
  reset() {
    this.formResponses = null;
    this.imageGroup = null;
    this.existingImages = [];
    this.feature = null;
    this.imagesToDelete = [];
    this.uploadResponse = null;
    this.complete = false;
    this.pendingUploadGroup = null;
    this.pendingFiles = [];
    this.uploadState = UploadState.PENDING;
    this.complete = false;
    this.loading = false;
  }

  // This hackiness is here so that the upload form is redrawn everytime the
  // form template changes. Effectively killing the previous state. Another
  // way to do is through the use of component refs but that is arguably even
  // more gross. See https://angular.io/api/core/ComponentRef
  showNewForm() {
    // Save pending selection to persist in-between annotation editor
    // navigation.
    this.pendingUploadService.setPendingFormTemplate(this.formTemplate);
    this.currentForm = null;
    setTimeout(() => {
      this.currentForm = this.formTemplate;
    }, 0);
  }

  /**
   * Opens form reset confirmation dialog.
   */
  private openResetFormDialog(): Observable<boolean> {
    return this.dialogService.render<ResetFormDialog, boolean>(ResetFormDialog).pipe(
      tap(() => {
        this.loading = true;
      }),
      finalize(() => {
        this.loading = false;
      }),
      takeUntil(this.destroyed),
    );
  }

  private isAnnotationUrl(url: string) {
    // Annotation editor can either be on its separate page or as part of
    // lightbox.
    return url.includes(ROUTE.LIGHTBOX);
  }

  private resetFormState() {
    this.reset();
    this.pendingUploadService.clearPendingState();
    this.showNewForm();
  }

  private saveAnnotationsOfPendingUpload(pendingFile: PendingFile, annotations: Annotation[]) {
    this.annotationsService.addPendingAnnotations(pendingFile.id, {
      id: pendingFile.id,
      annotations,
      updatedImadeURL: '',
    });
    this.annotationsService
      .saveAnnotationsForImage(pendingFile.id)
      .pipe(
        catchError((error: Error) => {
          console.error(`Failed to save annotations. ${error.message}`);
          return of(null);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }
}

function getPointLocation(feature: Feature | null): LatLng | null {
  if (feature?.geometry?.geometry.case !== 'point') {
    return null;
  }
  return feature?.geometry?.geometry?.value?.location || null;
}

function convertResponsesToForm(responses: UploadFormResponses): UploadForm {
  return {
    externalId: responses.asset?.externalId || responses.externalId,
    tags: responses.tags || new Set(),
    extraProperties: responses.extraProperties || [],
  };
}

function assetInitialFormData(asset: Feature, isOnline: boolean): UploadFormResponses {
  const location = getPointLocation(asset);
  if (!location && isOnline) {
    console.error(`point location not found on asset: ${asset}`);
    throw new Error(`point location not found on asset: ${asset}`);
  }
  return {
    pendingFiles: [],
    tags: null,
    location,
    asset,
    externalId: asset.externalId,
    extraProperties: [],
    existingImages: [],
    imagesToDelete: [],
  };
}
