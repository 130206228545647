import {
  CallOptions,
  Code,
  ConnectError,
  PromiseClient,
  Transport,
  createPromiseClient,
} from '@connectrpc/connect';
import {createGrpcWebTransport} from '@connectrpc/connect-web';

import {Injectable} from '@angular/core';

import {CommentService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/commentservice_connect';
import {EmptyService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/emptyservice_connect';
import {FilterViewService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/filterviewservice_connect';
import {ImageService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/imageservice_connect';
import {LayerService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layerservice_connect';
import {SunroofService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/sunroofservice_connect';
import {TagService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tagservice_connect';
import {UserService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/userservice_connect';

import {AnalyticsService, EventActionType, EventCategoryType} from './analytics_service';
import {AuthService} from './auth_service';
import {ConfigService} from './config_service';

/**
 * Service for managing GridAware OnePlatform API clients.
 */
@Injectable()
export class ApiService {
  apiKey!: string;
  apiUrl!: string;
  backendUrl!: string;
  transport!: Transport;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
  ) {
    this.apiUrl = this.configService.onePlatformUrl;
    this.apiKey = this.configService.onePlatformApiKey;
    this.backendUrl = this.configService.gridawareBackendUrl;
    this.transport = createGrpcWebTransport({
      baseUrl: this.backendUrl,

      // By default, this transport uses the binary format, because
      // not all gRPC-web implementations support JSON.
      useBinaryFormat: true,

      // Controls what the fetch client will do with credentials, such as
      // Cookies. The default value is "same-origin", which will not
      // transmit Cookies in cross-origin requests.
      credentials: 'same-origin',
    });
  }

  createSunroofServiceBEClient(): PromiseClient<typeof SunroofService> {
    return createPromiseClient(SunroofService, this.transport);
  }

  createEmptyServiceBEClient(): PromiseClient<typeof EmptyService> {
    return createPromiseClient(EmptyService, this.transport);
  }

  createTagServiceBEClient(): PromiseClient<typeof TagService> {
    return createPromiseClient(TagService, this.transport);
  }

  createImageServiceBEClient(): PromiseClient<typeof ImageService> {
    return createPromiseClient(ImageService, this.transport);
  }

  createUserServiceBEClient(): PromiseClient<typeof UserService> {
    return createPromiseClient(UserService, this.transport);
  }

  createFilterViewServiceBEClient(): PromiseClient<typeof FilterViewService> {
    return createPromiseClient(FilterViewService, this.transport);
  }

  createLayerServiceBEClient(): PromiseClient<typeof LayerService> {
    return createPromiseClient(LayerService, this.transport);
  }

  createCommentServiceBEClient(): PromiseClient<typeof CommentService> {
    return createPromiseClient(CommentService, this.transport);
  }

  /**
   * Invokes a provided JSPB client method with gRPC metadata.
   */
  withMetadata<Response>(
    fn: (metadata: {[key: string]: string} | null) => Promise<Response>,
  ): Promise<Response> {
    return this.getMetadata().then((metadata) => fn(metadata));
  }

  /**
   * Constructs gRPC metadata, possibly including a JavaScript Web
   * Token string drawn from GCIP.
   *
   * @return An object representation of gRPC metadata, for use with
   * JSPB client methods.
   */
  private getMetadata(): Promise<{[key: string]: string}> {
    return this.authService.getAuthHeaders().then((data) => {
      const headers = {...data};
      headers['X-Goog-Api-Key'] = this.apiKey;
      return headers;
    });
  }

  /**
   * Invokes a provided ConnectRPC client method with gRPC call options
   * that include the authorization token. If the server is unavailable,
   * the call will be retried.
   */
  async withCallOptions<Response>(
    fn: (callOptions: CallOptions) => Promise<Response>,
  ): Promise<Response> {
    // Wait for firebase initialization to complete and only make the gRPC call if user is
    // logged-in.
    const userLoggedIn = await this.authService.isUserLoggedIn();
    if (userLoggedIn) {
      return this.authService
        .getHttpHeaders()
        .then((headers) => this.withRetries(() => fn({headers: headers})));
    } else {
      throw new Error('Unauthenticated user');
    }
  }

  /**
   * Invokes a provided ConnectRPC client method, retrying
   * the call whenever server unavailability is encountered.
   */
  async withRetries<Response>(
    fn: () => Promise<Response>,
    maxRetryAttempts: number = 5,
    baseDelayMs: number = 1000,
    retryNumber: number = 0,
  ): Promise<Response> {
    try {
      return await fn();
    } catch (error) {
      if (
        error instanceof ConnectError &&
        error.code === Code.Unavailable &&
        retryNumber < maxRetryAttempts
      ) {
        const delayMs = baseDelayMs * 2 ** retryNumber;
        await new Promise((resolve) => setTimeout(resolve, delayMs));
        const msg = `GRPC call resulted in error (${error.message}). Retry attempt #${
          retryNumber + 1
        } of ${maxRetryAttempts}. Delay: ${delayMs}ms.`;
        console.warn(msg);
        this.analyticsService.sendEvent(EventActionType.REQUEST_RETRY, {
          event_category: EventCategoryType.ERROR,
          event_label: msg,
        });
        return this.withRetries(fn, maxRetryAttempts, baseDelayMs, retryNumber + 1);
      } else {
        throw error;
      }
    }
  }
}
