import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {animate, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import {UserType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/user_pb';

import {BannerLevel} from '../banner/banner';
import {ROUTE} from '../constants/paths';
import {MessageCategory, NavigationSnackBar} from '../navigation_snackbar/navigation_snackbar';
import {OfflineDialog} from '../pending_upload/dialogs/offline_dialog';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {AuthService} from '../services/auth_service';
import {ConfigService} from '../services/config_service';
import {DialogService} from '../services/dialog_service';
import {FeedbackService} from '../services/feedback_service';
import {LocalStorageService} from '../services/local_storage_service';
import {NetworkService} from '../services/network_service';
import {OfflineAssetsService} from '../services/offline_assets_service';
import {
  PendingUploadQueueService,
  PendingUploadState,
} from '../services/pending_upload_queue_service';
import {UploadService} from '../services/upload_service';
import {UserPreferencesService} from '../services/user_preferences_service';
import {UsersService} from '../services/users_service';
import {ColorScheme} from '../typings/common';
import {UploadState} from '../typings/upload';
import {UserTypesDialog} from '../user_types/user_types_dialog';

const OFFLINE_DIALOG_WIDTH = '312px';
const ANIMATION_TIMING = '500ms';

/**
 * Standard application-wide header component.
 */
@Component({
  selector: 'header',
  templateUrl: './header.ng.html',
  styleUrls: ['./header.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({transform: 'translateY(100%)', opacity: 0}),
        animate(ANIMATION_TIMING, style({transform: 'translateY(0)', opacity: 1})),
      ]),
      transition(':leave', [
        style({transform: 'translateY(0)', opacity: 1}),
        animate(ANIMATION_TIMING, style({transform: 'translateY(100%)', opacity: 0})),
      ]),
    ]),
  ],
})
export class Header implements OnInit, OnDestroy {
  @Input() bannerTemplate: TemplateRef<unknown> | null = null;

  showUserType = false;
  userType: UserType | null = null;
  userImage = '';
  offline: boolean | null = null;
  offlineSnackBarRef: MatSnackBarRef<TextOnlySnackBar> | null = null;
  pendingUploadCount = 0;
  isInOfflineMode = false;
  numberOfOfflineAssets = 0;
  isOfflineBannerVisible = false;
  // offlineBannerLevel = BannerLevel.ANNOUNCEMENT;
  colorScheme = ColorScheme.LIGHT;
  themingEnabled = false;
  offlineBannerLevel = BannerLevel.ANNOUNCEMENT;
  private readonly destroyed = new Subject<void>();

  readonly ColorScheme = ColorScheme;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly authService: AuthService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly configService: ConfigService,
    private readonly dialogService: DialogService,
    private readonly feedbackService: FeedbackService,
    private readonly uploadService: UploadService,
    private readonly localStorageService: LocalStorageService,
    private readonly networkService: NetworkService,
    private readonly ngZone: NgZone,
    private readonly offlineAssetsService: OfflineAssetsService,
    private readonly pendingUploadQueueService: PendingUploadQueueService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly userPreferencesService: UserPreferencesService,
    // The users service needs to be instantiated even though it is not used
    // in this service. userService.getUserTypes needs to be called. This
    // relates to the interaction between the Header, Users Service,
    // User Preferences Service and the User Types Dialog.
    readonly usersService: UsersService,
  ) {}

  async ngOnInit() {
    this.showUserType = this.configService.userTypesEnabled;
    this.userImage = this.authService.getUserImage();
    this.isInOfflineMode = this.networkService.getExplicitOfflineMode();
    this.offlineAssetsService
      .onAssetsCountChanged()
      .pipe(takeUntil(this.destroyed))
      .subscribe((numberOfAssets: number) => {
        this.numberOfOfflineAssets = numberOfAssets;
      });
    this.userPreferencesService
      .getSelectedUserType()
      .pipe(takeUntil(this.destroyed))
      .subscribe((userType: UserType | null) => {
        this.userType = userType;
        if (this.showUserType && !this.userType) {
          this.openUserTypesDialog();
        }
      });
    this.userPreferencesService
      .getColorScheme()
      .pipe(takeUntil(this.destroyed))
      .subscribe((colorScheme: ColorScheme) => {
        this.colorScheme = colorScheme;
      });

    this.uploadService
      .getPendingUploadCount()
      .pipe(takeUntil(this.destroyed))
      .subscribe((pendingUploadCount: number) => {
        this.pendingUploadCount = pendingUploadCount;
        this.changeDetectorRef.detectChanges();
      });

    this.networkService
      .getOffline$()
      .pipe(takeUntil(this.destroyed))
      .subscribe((offline: boolean) => {
        this.isOfflineBannerVisible = this.offline !== null && offline;
        this.offline = offline;
        const firstTimeOffline = this.offline && !this.localStorageService.readOfflineDialogShown();
        if (firstTimeOffline) {
          this.dialogService.render<OfflineDialog, void>(OfflineDialog, {
            width: OFFLINE_DIALOG_WIDTH,
          });
          this.localStorageService.writeOfflineDialogShown();
        }
        this.changeDetectorRef.detectChanges();
      });
    this.pendingUploadQueueService
      .getUploadUpdates()
      .pipe(takeUntil(this.destroyed))
      .subscribe((update: PendingUploadState | null) => {
        if (update !== null) {
          this.renderPendingUploadSnackBar(update);
          this.pendingUploadQueueService.clearUploadUpdates();
        }
      });
    this.themingEnabled = this.configService.themingEnabled;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  toggleTheme() {
    this.userPreferencesService.setColorScheme(
      this.colorScheme === ColorScheme.DARK ? ColorScheme.LIGHT : ColorScheme.DARK,
    );
  }

  signOut() {
    if (!window.confirm('Are you sure you want to sign out?')) {
      return;
    }
    this.authService.signOut().then(() => {
      this.changeDetectorRef.detectChanges();
      this.ngZone.run(() => this.router.navigateByUrl(ROUTE.LOGIN));
    });
  }

  private renderPendingUploadSnackBar(update: PendingUploadState) {
    if (update.state === UploadState.PENDING) {
      const count = update.uploadData?.files.length || 0;
      const message =
        count > 0 ? `Uploading ${count} file${count > 1 ? 's' : ''}...` : 'Updating image group...';
      this.snackBar.open(message);
      return;
    }

    let message = '';
    let linkLabel = '';
    let link = '';
    let category = MessageCategory.INFO;
    switch (update.state) {
      case UploadState.SUCCEEDED: {
        message = 'Upload succeeded.';
        linkLabel = 'View';
        link = update.defect ? `/map/defects/${update.defect.id}` : '';
        category = MessageCategory.SUCCESS;
        break;
      }
      case UploadState.EDIT_SUCCEEDED: {
        message = 'Edit succeeded.';
        linkLabel = 'View';
        link = update.defect ? `/map/defects/${update.defect.id}` : '';
        category = MessageCategory.SUCCESS;
        break;
      }
      case UploadState.EDIT_FAILED: {
        message = 'Edit failed.';
        linkLabel = 'View';
        link = update.defect ? `/map/defects/${update.defect.id}` : '';
        category = MessageCategory.FAILURE;
        break;
      }
      case UploadState.REMOVED: {
        message = 'Defect successfully removed.';
        linkLabel = '';
        link = '';
        category = MessageCategory.SUCCESS;
        break;
      }
      default: {
        message = 'Upload failed.';
        linkLabel = 'Pending queue.';
        link = '/upload/pending';
        category = MessageCategory.FAILURE;
        break;
      }
    }
    const snackBarDurationInSeconds = 10;
    this.snackBar.openFromComponent(NavigationSnackBar, {
      data: {
        message,
        category,
        linkLabel,
        link,
      },
      duration: snackBarDurationInSeconds * 1000,
    });
  }

  triggerFeedback() {
    // TODO(b/339559258): Integrate Google feedback.
  }

  openUserTypesDialog() {
    this.dialogService.render<UserTypesDialog, void>(UserTypesDialog);
  }

  openPhotoUpload() {
    if (this.configService.uploadFormImprovementsEnabled) {
      this.uploadService.renderUploadDialog();
    } else {
      this.router.navigateByUrl(ROUTE.PHOTO_UPLOAD);
    }
  }

  goToPendingUploads() {
    this.router.navigateByUrl(ROUTE.PENDING_UPLOAD);
  }

  toggleOfflineMode(event: MatSlideToggleChange) {
    this.analyticsService.sendEvent(EventActionType.OFFLINE_MODE_TOGGLED, {
      event_category: EventCategoryType.MAP,
      event_label: String(event.checked),
    });
    this.isInOfflineMode = event.checked;
    this.networkService.setExplicitOfflineMode(event.checked);
  }

  dismissOfflineBanner() {
    this.isOfflineBannerVisible = false;
  }

  getThemeLabel() {
    return this.colorScheme === ColorScheme.DARK ? 'Change to light theme' : 'Change to dark theme';
  }

  startFeedback() {
    this.feedbackService.start();
  }
}
